export const categoryOptionsStoryline = {
  Genre: ["Action", "Comedy", "Fantasy", "Mystery", "Spy Fiction"],
  Setting: [
    "Outer Space",
    "School",
    "Prehistoric Times with Dinosaurs",
    "Underwater",
    "Wild West with Cowboys​",
  ],
};

export const categoryOptionsProtagonistCharacteristics = {
  Name: [],
  Age: ["3 to 4", "5 to 7", "8 to 10"],
  Gender: ["Male", "Female", "Other"],
  Hair_Color: ["Black", "Brown", "Blonde", "Red"],
  Eye_Color: ["Black", "Brown", "Blue", "Green"],
  Skin_Tone: ["White", "Brown", "Black"],
  Loves_To: ["Play with trucks", "Play with dolls", "Play with dinosours"],
};

export const categoryOptionsProtagonistCharacteristicsModeMit = {
  Name: ["John", "Lea", "Anna", "Kevin"],
  Gender: ["Male", "Female", "Other"],
  Favorite_Object: ["Laptop", "Earings"],
  AI_Concern: ["Takes over my job"],
};

export const ageOptions = [
  { value: "3 to 4", label: "3 to 4" },
  { value: "4 to 6", label: "4 to 6" },
  { value: "6 to 9", label: "6 to 9" },
];

export const storedIds = [
  "Commitment when reading",
  "Compassion",
  "Courage",
  "Empathy",
  "Fair play",
  "Finding the bright side",
  "Helping others",
  "Honesty",
  "Generosity",
  "Positive conflict resolution",
  "Leadership",
  "Learn and practice colors",
  "Teamwork",
  "The importance of going to sleep early for a healthy body and mind",
  "The importance of keeeping a clean space for a healthy body and mind",
  "The positive use of humor",
  "Self-defence in the playground",
  "Sharing",
  "Treating others as you want to be treated (The Golden Rule)",
  "Learn and practice identifying animals",
  "Learn and practice identifying body parts",
  "Learn and practice identifying body colors",
  "Learn and practice identifying body foods",
  "Learn and practice identifying body letters",
  "Learn and practice identifying body objects",
  "Learn and practice identifying body shapes",
  "Learn and practice identifying body words",
  "Learn and practice numbers and counting",
];

export const generateNoImagesPerPara = 1;