import { createRoot } from "react-dom/client";

import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.min.css";
// import "assets/styles/docs.css";
import Home from "screens/Home";
import Workspace from "screens/Workspace";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PrimeReactProvider } from "primereact/api";
import Login from "screens/Login";
import ProtectedRoute from "screens/nav/ProtectedRoute";
import { PersistGate } from "redux-persist/integration/react";
import "react-loading-skeleton/dist/skeleton.css";
import Profile from "screens/Profile";
import Register from "screens/Register";

// import "allotment/dist/style.css";
import "./App.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import UserAdmin from "screens/UserAdmin";
import Characters from "screens/Characters";
import AccessPage from "screens/AccessPage";

// Find the root element where you want to mount your React app
const rootElement = document.getElementById("root");

// Create a root
const root = createRoot(rootElement);

// Render your app component using the root.render method
root.render(
  <PrimeReactProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/accesspage" element={<AccessPage />} />
            <Route path="/dashboard" element={<ProtectedRoute />}>
              <Route path="workspace" element={<Workspace />} />
              <Route path="profile" element={<Profile />} />
              <Route path="users" element={<UserAdmin />} />
              <Route path="characters" element={<Characters />} />
            </Route>
          </Routes>
        </HashRouter>
      </PersistGate>
    </Provider>
  </PrimeReactProvider>
);
