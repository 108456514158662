import Button from "components/Elements/Button";
import React, { useState } from "react";
import CustomModalComponent from "screens/components/CustomModalComponent";
import "@pqina/pintura/pintura.css";
import { PinturaEditor } from "@pqina/react-pintura";

// Import the editor functionality
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_crop,
  plugin_finetune,
  plugin_resize,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_resize_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor and finetune plugins
  markup_editor_defaults,
  plugin_finetune_defaults,
} from "@pqina/pintura";

setPlugins(plugin_crop, plugin_finetune, plugin_resize);

function IterateUpload({singleFile=[], setSingleFile}) {  

  const [showImageEditor, setShowImageEditor] = useState(false);
  const [imageToEdit, setImageToEdit] = useState(null);

  const uploadSingleFiles = (e) => {    
    setSingleFile([...singleFile, {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0])
    }]);
  };

  const removeImage = (index) => {
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length),
    ]);
  };

  const editorConfig = {
    // This will read the image data (required)
    imageReader: createDefaultImageReader(),

    // This will write the output image
    imageWriter: createDefaultImageWriter({
      orientImage: true,
      copyImageHead: false,
      mimeType: "image/jpeg",
      quality: 1,
      format: "file",
      store: async function (imageData) {
        // var data = {
        //   para: index,
        //   image_url: imageToUpdate,
        //   image: await blobToBase64(imageData.blob),
        // };

        // await dispatch(updateImageUrl(data));
        
        var temp = singleFile;        
        temp[imageToEdit['index']] = [URL.createObjectURL(imageData.blob)]        
        setSingleFile(temp)
        setShowImageEditor(false);

        return true;
      },
    }),

    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,

    // The finetune util controls
    ...plugin_finetune_defaults,

    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),

    // The icons and labels to use in the user interface (required)
    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_resize_locale_en_gb,
      ...markup_editor_locale_en_gb,
    },
  };

  return (
    <div className="w-full flex items-center justify-center mt-4">
      <div className="w-full flex flex-row items-center justify-center gap-2">
        {singleFile.length > 0 &&
          singleFile.map((item, index) => {
            console.log(item)
            return (
              <div key={item.url} className="flex w-1/6 items-center justify-center">
                <div className="img-block bg-teal">
                  <img
                    className="img-block cursor-pointer"
                    src={item.url}
                    alt="..."
                    onClick={() => {
                      setImageToEdit({ url: item.url, index: index });
                      setShowImageEditor(true);
                    }}
                  />
                  <span
                    className="remove_img"
                    onClick={() => removeImage(index)}
                  >
                    X
                  </span>
                </div>
              </div>
            );
          })}
        {singleFile.length > 4 ? null : (
          <div className="upload-btn-wrapper cursor-pointer">
            <button className="cursor-pointer">+</button>
            <input type="file" name="myfile" onChange={uploadSingleFiles} />
          </div>
        )}
      </div>
      <CustomModalComponent
        displayDialog={showImageEditor}
        onHide={() => {
          setShowImageEditor(false);
        }}
        header="Image Editor"
      >
        <div className="w-full p-2" style={{ height: "600px" }}>
          <PinturaEditor {...editorConfig} src={imageToEdit !== null ? imageToEdit['url'] : ""} />
        </div>
      </CustomModalComponent>
    </div>
  );
}

export default IterateUpload;
