import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NavbarComponents from "../components/NavbarComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { fetchHelper } from "../helpers/fetchHelpers";
import { Toast } from "primereact/toast";
import CustomModalComponent from "../components/CustomModalComponent";
import {
  categoryOptionsProtagonistCharacteristics,
  categoryOptionsProtagonistCharacteristicsModeMit,
} from "../constants/OptionConstants";
import BubblesSelect from "../components/BubblesSelect";
import IterateUpload from "../WorkSpaceComponents/BuildStory/IterateUpload";
import Button from "components/Elements/Button";

function SavedCharacters() {
  const user = useSelector((state) => state.user);

  const [charactersLoading, setCharactersLoading] = useState(true);
  const [characters, setCharacters] = useState([]);
  const [characterImages, setCharacterImages] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const toastRef = useRef();

  const formInitialState = {
    id: undefined,
    characteristics: {
      Name: "",
      Age: "",
      Gender: "",
      Hair_Color: "",
      Eye_Color: "",
      Skin_Tone: "",
      Loves_To: "",
    },
    otherCharacteristics: "",
    isOtherVisible: {
      characteristics: false,
    },
  };

  const [form, setForm] = useState(formInitialState);

  const getCharacters = async () => {
    setCharactersLoading(true);
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/list-characters`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {
      setCharacters(message);
    }
    setCharactersLoading(false);
  };

  const header = (
    <div className="w-full flex flex-row justify-between items-center">
      <span className="text-xl text-900 font-bold">Characters</span>
      <Button
        color={"teal"}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add Characters
      </Button>
    </div>
  );

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [IsCustomSelected, setIsCustomSelected] = useState(false);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(""); // Reset subcategory when a new category is selected
    setIsCustomSelected(false);
  };

  const handleSelectSubcategory = (formField, category, subcategory) => {
    setForm((prevForm) => ({
      ...prevForm,
      [formField]: {
        ...prevForm[formField],
        [category]: subcategory,
      },
    }));
  };

  const handleOtherSelected = (formField) => {
    setIsCustomSelected(true); // Indicate custom selection
    setSelectedCategory("");
    setSelectedSubCategory("");

    setForm((prevForm) => ({
      ...prevForm,
      isOtherVisible: {
        ...prevForm.isOtherVisible,
        [formField]: true,
      },
    }));
  };

  useEffect(() => {
    getCharacters();

    return () => {};
  }, []);

  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const handleAddCharacter = async () => {
    setFormSubmitLoading(true);
    var fd = new FormData();
    fd.append("name", form.characteristics.Name);
    fd.append("age", form.characteristics.Age);
    fd.append("gender", form.characteristics.Gender);
    fd.append("hair_color", form.characteristics.Hair_Color);
    fd.append("eye_color", form.characteristics.Eye_Color);
    fd.append("skin_tone", form.characteristics.Skin_Tone);
    fd.append("loves_to", form.characteristics.Loves_To);
    fd.append("custom_details", form.otherCharacteristics);
    fd.append("id", form.id === undefined ? "" : form.id);

    characterImages.map((item, index) => {
      fd.append(`image_${index}`, item.file);
    });

    let result;

    if (form.id === undefined) {
      result = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/user/add-character`,
        {
          method: "POST",
          body: fd,
          headers: {
            Authorization: user.token,
          },
        }
      );
    } else {
      result = await fetchHelper(
        `${process.env.REACT_APP_API_BASE_URL}/user/update-character`,
        {
          method: "POST",
          body: fd,
          headers: {
            Authorization: user.token,
          },
        }
      );
    }

    if (result.error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
      });
    } else {
      getCharacters();
    }
    setShowModal(false);
    setForm(formInitialState);
    setFormSubmitLoading(false);
  };

  const handleEdit = (character) => {
    setForm({
      id: character.id,
      characteristics: {
        Name: character.name,
        Age: character.age,
        Gender: character.gender,
        Hair_Color: character.hair_color,
        Eye_Color: character.eye_color,
        Skin_Tone: character.skin_tone,
        Loves_To: character.loves_to,
      },
      otherCharacteristics: character.custom_details,
      isOtherVisible: {
        characteristics: character.custom_details.length > 0,
      },
    });
    setCharacterImages(character.images);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/delete-character`,
      {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {
      getCharacters();
    }
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  const actionCell = (character) => {
    return (
      <div className="w-full flex flex-row gap-2 items-center">
        <Button
          color={"indigo"}
          onClick={() => {
            handleEdit(character);
          }}
        >
          Edit
        </Button>
        <Button
          color={"red"}
          onClick={() => {
            handleDelete(character.id);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="w-full flex flex-row mt-4 justify-between">
        <div className="w-full p-2 flex flex-col">
          {charactersLoading ? (
            <ProgressSpinner />
          ) : (
            <DataTable header={header} value={characters}>
              <Column field="name" header="Name"></Column>
              <Column field="age" header="Age"></Column>
              <Column field="gender" header="Gender"></Column>
              <Column field="hair_color" header="Hair Color"></Column>
              <Column field="eye_color" header="Eye Color"></Column>
              <Column field="skin_tone" header="Skin Tone"></Column>
              <Column field="loves_to" header="Loves To"></Column>
              <Column field="custom_details" header="Custom Details"></Column>
              <Column header="Action" body={actionCell}></Column>
            </DataTable>
          )}
        </div>
      </div>
      <CustomModalComponent
        displayDialog={showModal}
        onHide={() => {
          setShowModal(!showModal);
          setForm(formInitialState);
        }}
        header={"Character"}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full items-center flex flex-col justify-center">
            <BubblesSelect
              categories={
                user.role === "sfb"
                  ? categoryOptionsProtagonistCharacteristicsModeMit
                  : categoryOptionsProtagonistCharacteristics
              }
              onSelectCategory={handleSelectCategory}
              onSelectSubcategory={handleSelectSubcategory}
              selectedCategory={selectedCategory}
              selectedSubcategory={form.characteristics[selectedCategory]}
              enableOther={true}
              onOtherSelected={() => handleOtherSelected("characteristics")}
              formField="characteristics"
              form={form}
              setForm={setForm}
              isCustomSelected={IsCustomSelected}
            />
          </div>
          {IsCustomSelected && (
            <div className="dropdown-row">
              <textarea
                value={form.otherCharacteristics}
                onChange={(e) =>
                  setForm({ ...form, otherCharacteristics: e.target.value })
                }
                placeholder="Describe custom protagonist's characteristics"
                className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
          )}
        </div>
        <IterateUpload
          singleFile={characterImages}
          setSingleFile={setCharacterImages}
        />
        <div className="w-full flex items-center justify-center mt-4">
          {formSubmitLoading ? (
            <ProgressSpinner />
          ) : (
            <Button color={"teal"} onClick={handleAddCharacter}>
              {form.id !== undefined ? "Update" : "Submit"}
            </Button>
          )}
        </div>
      </CustomModalComponent>
      <Toast ref={toastRef} />
    </>
  );
}

export default SavedCharacters;
