import Button from "components/Elements/Button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BubblesSelect from "screens/components/BubblesSelect";
import CustomModalComponent from "screens/components/CustomModalComponent";
import { categoryOptionsProtagonistCharacteristics } from "screens/constants/OptionConstants";
import { fetchHelper } from "screens/helpers/fetchHelpers";
import IterateUpload from "./IterateUpload";

function CharacterComponent({ characters = [], setCharacters }) {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [IsCustomSelected, setIsCustomSelected] = useState(false);
  const user = useSelector((state) => state.user);

  const [showModal, setShowModal] = useState(false);

  const [savedCharacters, setSavedCharacters] = useState([]);
  const [characterImages, setCharacterImages] = useState([]);

  const [isCharacterSheetGenerated, setIsCharacterSheetGenerated] = useState(
    false
  );
  const [characterSheetLoading, setCharacterSheetLoading] = useState(false);

  const toastRef = useRef();

  const formInitialState = {
    id: undefined,
    characteristics: {
      Name: "",
      Age: "",
      Gender: "",
      Hair_Color: "",
      Eye_Color: "",
      Skin_Tone: "",
      Loves_To: "",
    },
    otherCharacteristics: "",
    isOtherVisible: {
      characteristics: false,
    },
  };
  const [form, setForm] = useState(formInitialState);

  const getSavedCharacters = async () => {
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/user/list-characters`,
      {
        method: "GET",
        headers: {
          Authorization: user.token,
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );

    if (error) {
      setSavedCharacters([{ name: "No Saved Characters" }]);
    } else {
      setSavedCharacters(message);
    }
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(""); // Reset subcategory when a new category is selected
    setIsCustomSelected(false);
  };

  const handleSelectSubcategory = (formField, category, subcategory) => {
    setForm((prevForm) => ({
      ...prevForm,
      [formField]: {
        ...prevForm[formField],
        [category]: subcategory,
      },
    }));
  };

  const handleOtherSelected = (formField) => {
    setIsCustomSelected(true); // Indicate custom selection
    setSelectedCategory("");
    setSelectedSubCategory("");

    setForm((prevForm) => ({
      ...prevForm,
      isOtherVisible: {
        ...prevForm.isOtherVisible,
        [formField]: true,
      },
    }));
  };

  const header = (
    <div className="w-full flex flex-row justify-between items-center">
      <span className="text-xl text-900 font-bold">Characters</span>
      <Button
        color={"teal"}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add Characters
      </Button>
    </div>
  );

  const actionCell = (character) => {
    return (
      <div className="w-full flex flex-row gap-2 items-center">
        <Button
          color={"indigo"}
          onClick={() => {
            handleEdit(character);
          }}
        >
          Edit
        </Button>
        <Button
          color={"red"}
          onClick={() => {
            handleDeleteCharacter(character.id);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const handleAddCharacter = () => {
    let temp = form;
    temp.id = characters.length + 1;
    temp.images = characterImages;

    setCharacters([...characters, temp]);
    setForm(formInitialState);
    setShowModal(false);
    setCharacterImages([]);
  };

  const handleDeleteCharacter = (id) => {
    setCharacters(characters.filter((item) => item.id !== id));
  };

  const handleEdit = (character) => {
    setForm(character);
    setCharacterImages(character.images);
    setShowModal(true);
  };

  const handleUpdateCharacter = () => {
    let temp = characters.filter((item) => item.id !== form.id);
    let formTemp = form;
    formTemp.images = characterImages;
    temp.push(formTemp);
    setCharacters(temp);
    setForm(formInitialState);
    setShowModal(false);
    setCharacterImages([]);
  };

  useEffect(() => {
    console.log(form);

    return () => {};
  }, [form]);

  useEffect(() => {
    getSavedCharacters();

    return () => {};
  }, []);

  const selectSavedCharacter = (character) => {
    setForm({
      id: undefined,
      characteristics: {
        Name: character.name,
        Age: character.age,
        Gender: character.gender,
        Hair_Color: character.hair_color,
        Eye_Color: character.eye_color,
        Skin_Tone: character.skin_tone,
        Loves_To: character.loves_to,
      },
      otherCharacteristics: character.custom_details,
      isOtherVisible: {
        characteristics: false,
      },
    });
  };

  const getCharacterSheet = async () => {
    setCharacterSheetLoading(true);
    var data = {
      id: "",
      name: form.characteristics.Name,
      age: form.characteristics.Age,
      gender: form.characteristics.Gender,
      hair_color: form.characteristics.Hair_Color,
      eye_color: form.characteristics.Eye_Color,
      skin_tone: form.characteristics.Skin_Tone,
      loves_to: form.characteristics.Loves_To,
      custom_details: form.otherCharacteristics,
    };

    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/story/character-sheet`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: user.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {      
      setCharacterImages([
        ...characterImages,
        {
          url: message['image'],
        },
      ]);
      setIsCharacterSheetGenerated(true);
    }
    setCharacterSheetLoading(false);
  };

  return (
    <div className="w-full flex flex-row mt-4 justify-between">
      <div className="w-full p-2 flex flex-col">
        <DataTable header={header} value={characters}>
          <Column field="characteristics.Name" header="Name"></Column>
          <Column field="characteristics.Age" header="Age"></Column>
          <Column field="characteristics.Gender" header="Gender"></Column>
          <Column
            field="characteristics.Hair_Color"
            header="Hair Color"
          ></Column>
          <Column field="characteristics.Eye_Color" header="Eye Color"></Column>
          <Column field="characteristics.Skin_Tone" header="Skin Tone"></Column>
          <Column field="characteristics.Loves_To" header="Loves To"></Column>
          <Column field="otherCharacteristics" header="Custom Details"></Column>
          <Column header="Action" body={actionCell}></Column>
        </DataTable>
      </div>
      <CustomModalComponent
        displayDialog={showModal}
        onHide={() => {
          setShowModal(!showModal);
          setForm(formInitialState);
        }}
        header={"Character"}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full items-center flex flex-col justify-center">
            <Dropdown
              onChange={(e) => {
                selectSavedCharacter(e.value);
                setCharacterImages(e.value["images"]);
              }}
              options={savedCharacters}
              optionLabel="name"
              placeholder="Saved Characters"
              filter
              className="border items-center p-2 mb-2"
            />
            <BubblesSelect
              categories={categoryOptionsProtagonistCharacteristics}
              onSelectCategory={handleSelectCategory}
              onSelectSubcategory={handleSelectSubcategory}
              selectedCategory={selectedCategory}
              selectedSubcategory={form.characteristics[selectedCategory]}
              enableOther={true}
              onOtherSelected={() => handleOtherSelected("characteristics")}
              formField="characteristics"
              form={form}
              setForm={setForm}
              isCustomSelected={IsCustomSelected}
            />
          </div>
          {IsCustomSelected && (
            <div className="dropdown-row">
              <textarea
                value={form.otherCharacteristics}
                onChange={(e) =>
                  setForm({ ...form, otherCharacteristics: e.target.value })
                }
                placeholder="Describe custom protagonist's characteristics"
                className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
          )}
        </div>
        <IterateUpload
          singleFile={characterImages}
          setSingleFile={setCharacterImages}
        />
        <div className="w-full flex items-center justify-center mt-4">
          {isCharacterSheetGenerated && (
            <Button
              color={"teal"}
              onClick={
                form.id !== undefined
                  ? handleUpdateCharacter
                  : handleAddCharacter
              }
            >
              {form.id !== undefined ? "Update" : "Submit"}
            </Button>
          )}
          <Button
            color={characterSheetLoading ? "" : "teal"}
            disabled={characterSheetLoading}
            onClick={getCharacterSheet}
          >
            {characterSheetLoading
              ? "Generating..."
              : "Generate Character Sheet"}
          </Button>
        </div>
      </CustomModalComponent>
      <Toast ref={toastRef} />
    </div>
  );
}

export default CharacterComponent;
