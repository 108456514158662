import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import { useState } from "react";
import Footer from "./components/Footer";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useNavigate } from "react-router";
import AccessPageContent from "views/presentation/AccessPageContent";

function AccessPage() {

  const toastRef = useRef();

  const navigate = useNavigate()

  const handleSubmit = async () => {
    // setRegisterLoading(true);
    // Handle submit when needed
  };

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents />
      <main>
        <section className="relative w-full h-full py-20">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${
                require("assets/img/register_bg_2.png").default
              })`,
            }}
          />
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full  px-4">
              <React.Fragment>
                <div className="pt-17">
                  <AccessPageContent />
                </div>
              </React.Fragment>

              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <Toast ref={toastRef} />
    </div>
  );
}

export default AccessPage;
