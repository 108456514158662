import Button from "components/Elements/Button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchHelper } from "screens/helpers/fetchHelpers";

function StoryCharacters() {
  const [characters, setCharacters] = useState([]);
  const [charactersLoding, setCharactersLoding] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toastRef = useRef();

  const user = useSelector((state) => state.user);

  const getCharacters = async () => {
    setCharactersLoding(true);
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/story/list-story-characters?page=${page}&per_page=${perPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
      });
    } else {
      setCharacters(message["story_characters"]);
      setTotalPages(message["total_pages"]);
    }
    setCharactersLoding(false);
  };

  useEffect(() => {
    getCharacters();

    return () => {};
  }, [page]);

  const header = (
    <div className="w-full flex flex-row justify-between items-center">
      <span className="text-xl text-900 font-bold">Characters</span>
    </div>
  );

  const addCharacter = async (id) => {
    const data = {
      id,
    };
    const { error, message } = await fetchHelper(
      `${process.env.REACT_APP_API_BASE_URL}/story/save-character`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      }
    );

    if (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: JSON.parse(message)['message'],
      });
    } else {
      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: "Added",
      });
    }
  };

  const actionCell = (character) => {
    return (
      <div className="w-full flex flex-row gap-2 items-center">
        <Button
          color={"indigo"}
          onClick={async (e) => {
            const button = e.target;
            button.innerText = "Saving Character...";
            await addCharacter(character.id);
            button.innerText = "Save Character";
          }}
        >
          Save Character
        </Button>
      </div>
    );
  };

  const updatePage = (action) => {
    if (charactersLoding) {
      return;
    }

    if (action == "up" && page < totalPages) {
      setPage(page + 1);
    }

    if (action == "down" && page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <div className="w-full flex flex-row mt-4 justify-between">
        <div className="w-full p-2 flex flex-col">
          {charactersLoding ? (
            <ProgressSpinner />
          ) : (
            <DataTable
              paginator
              rows={10}
              rowsPerPageOptions={[10, 15, 20]}
              header={header}
              value={characters}
            >
              <Column field="name" header="Name"></Column>
              <Column field="age" header="Age"></Column>
              <Column field="gender" header="Gender"></Column>
              <Column field="hair_color" header="Hair Color"></Column>
              <Column field="eye_color" header="Eye Color"></Column>
              <Column field="skin_tone" header="Skin Tone"></Column>
              <Column field="loves_to" header="Loves To"></Column>
              <Column field="custom_details" header="Custom Details"></Column>
              <Column field="story" header="Story Name"></Column>
              <Column field="story_created" header="Story Created On"></Column>
              <Column header="Action" body={actionCell}></Column>
            </DataTable>
          )}
        </div>
      </div>
      <Toast ref={toastRef} />
    </>
  );
}

export default StoryCharacters;
