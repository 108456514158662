import React from "react";
import NavbarComponents from "./components/NavbarComponent";
import CardComponent from "./components/CardComponent";
import { TabPanel, TabView } from "primereact/tabview";
import EditStory from "./WorkSpaceComponents/EditStory";
import { useState } from "react";
import SimpleStory from "./WorkSpaceComponents/BuildStory/SimpleStory";
import { useSelector } from "react-redux";
import GetStory from "./WorkSpaceComponents/GetStory";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PreviewStory from "./WorkSpaceComponents/PreviewStory";
import Button from "components/Elements/Button";
import PDFStory from "./WorkSpaceComponents/PDFStory";

import jsPDF from "jspdf";
import { domToPng } from "modern-screenshot";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

function Workspace() {
  const [activeIndex, setActiveIndex] = useState(1);
  const [loadingStory, setLoadingStory] = useState(true);
  const { role } = useSelector((state) => state.user);
  const story = useSelector((state) => state.story);
  const handle = useFullScreenHandle();
  const [showPreview, setShowPreview] = useState(false);

  const [downloadPDFLoading, setDownloadPDFLoading] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  const downloadPDF = async () => {
    setDownloadPDFLoading(true);
    if (activeIndex !== 3) {
      setActiveIndex(3);
    }
    setTimeout(async () => {
      const options = {
        fileName: story.title.replace("Title: ", ""),
      };

      try {
        let titleCanvas = await domToPng(document.querySelector("#title"));

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: "a7",
        });

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        pdf.addImage(titleCanvas, "PNG", 0, 0, width, height);

        for (let i = 0; i < story.content.length; i++) {
          try {
            pdf.addPage("a7", "landscape");
            // let paraCanvas = await html2canvas(
            //   document.querySelector(`#id_${i}`), {
            //     useCORS: true,
            //     allowTaint: true,
            //   }
            // );
            console.log("Image added", i);
            let paraCanvas = await domToPng(document.querySelector(`#id_${i}`));
            pdf.addImage(paraCanvas, "PNG", 0, 0, width, height);
          } catch (e) {
            console.log("Images error");
            console.log(e);
          }
        }
        pdf.save(options["fileName"] + ".pdf");
      } catch (e) {
        console.log("Error");
        console.log("Error: ", e);
      }
      setActiveIndex(1);
      setDownloadPDFLoading(false);
    }, 1000);
  };

  return (
    <div className="bg-white docs-components-wrapper h-full w-full">
      <NavbarComponents
        whiteBackground={true}
        showAvatar={true}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <CardComponent>
        <TabView
          className="w-full h-full mx-4"
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="GET STORY">
            <GetStory
              loadingStory={loadingStory}
              setTabIndex={setActiveIndex}
              setLoadingStory={setLoadingStory}
              handle={handle}
              setShowPreview={setShowPreview}
            />
          </TabPanel>
          <TabPanel header="BUILD STORY">
            <SimpleStory
              setTabIndex={setActiveIndex}
              setLoading={setLoadingStory}
            />
          </TabPanel>
          <TabPanel header="EDIT STORY">
            <EditStory
              loadingStory={loadingStory}
              setLoadingStory={setLoadingStory}
              setTabIndex={setActiveIndex}
            />
          </TabPanel>
          <TabPanel header="PREVIEW STORY" disabled={true}>
            <PDFStory
              loadingStory={loadingStory}
              setLoadingStory={setLoadingStory}
              setTabIndex={setActiveIndex}
            />
          </TabPanel>
        </TabView>
      </CardComponent>
      {!loadingStory && (
        <div className="w-1/6 h-1/6 rounded-lg z-1 sticky bottom-0 right-0 p-2 cursor-pointer">
          {story.content.length > 0 && story.cover_url && (
            <div className="border-2 border-teal rounded-lg bg-white">
              <div className="w-full flex flex-col items-center justify-center">
                <p className="font-bold">PREVIEW</p>
              </div>
              <div
                className="w-full h-full flex flex-row space-x-1"
                onClick={() => {
                  setShowPreview(true);
                  handle.enter();
                }}
              >
                <div className="w-full h-full">
                  <img src={story.cover_url} className="w-full h-full" />
                </div>
              </div>
              {role === "admin" && (
                <div className="w-full p-2 items-center justify-center flex">
                  <Button
                    size={"sm"}
                    onClick={downloadPDF}
                    color={!downloadPDFLoading ? "teal" : ""}
                    disabled={downloadPDFLoading}
                  >
                    {!downloadPDFLoading ? "Download PDF" : "Downloading..."}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <FullScreen
        onChange={(state, fhandle) => {
          if (!state) {
            setShowPreview(false);
          }
        }}
        handle={handle}
        className="w-full h-full flex items-center justify-center"
      >
        {showPreview && <PreviewStory />}
      </FullScreen>
      {!loadingStory && (
        <div className="card flex justify-center">
          <Sidebar
            visible={showSidebar}
            onHide={() => setShowSidebar(false)}
            showCloseIcon
          >
            <h2 className="text-2xl font-bold mb-4">Story Properties</h2>
            {story.elements.map((item) => {
              let name = item.name;
              let properties = [];
              try {
                properties = JSON.parse(item.properties);
                console.log(properties);
              } catch (e) {}
              return (
                <div className="mb-8">
                  <h2 className="text-xl font-bold ">{name}</h2>
                  {Object.keys(properties).map((prop) => {
                    if (prop !== "images") {
                      return (
                        <div className="w-full flex mb-2 flex-col">
                          <h2 className="text-lg uppercase">{prop}</h2>
                          <InputTextarea autoResize value={properties[prop]} />
                        </div>
                      );
                    } else {
                      return (
                        <div className="w-full flex mb-2 flex-col">
                          <h2 className="text-lg uppercase">{prop}</h2>
                          <div className="w-full flex flex-row overflow-auto">
                            {properties[prop].map((image) => {
                              return (
                                <img className="" src={image.url} alt="..." />
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </Sidebar>
        </div>
      )}
    </div>
  );
}

export default Workspace;
