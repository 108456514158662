import React from "react";
import { useSelector } from "react-redux";
import NavbarComponents from "./components/NavbarComponent";
import { TabPanel, TabView } from "primereact/tabview";
import SavedCharacters from "./CharacterScreen/SavedCharacters";
import StoryCharacters from "./CharacterScreen/StoryCharacters";

function Characters() {
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-white docs-components-wrapper">
      <NavbarComponents whiteBackground={true} showAvatar={true} />
      <div className="w-full p-6 flex flex-col">
        <h2 className="text-lg">
          Welcome, <span className="text-xl font-bold">{user.fullName}</span>
        </h2>
        <TabView>
          <TabPanel header="Saved Characters">
            <SavedCharacters />
          </TabPanel>
          <TabPanel header="Story Characters">
           <StoryCharacters />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}

export default Characters;
